import RegsiterDetails from "../components/registerDetails";

const  Registration = () => {
    return (<>
    <p className="mt-[90px] mb-20">
        <RegsiterDetails/>
    </p>
    </>);
}

export default Registration;